<template>
	<div>
		<div class="content">
			<!-- 补单 -->
			<div class="mb20 pageTitle"><b>{{$t('main.orderMenu.additionalOrder')}}</b></div>
			<div class="tips cdanger mb20 textCenter">{{$t('main.orderMenu.prompt')}}</div>
			<div>
				<el-form class="form" ref="form" :model="form" label-width="180px" v-loading='loading'>
					<el-form-item :label="$t('main.orderMenu.merchantNumber')">
						<el-input v-model="business_no" :placeholder="$t('main.orderMenu.pleaseEnterTheMerchantNumber')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('main.orderMenu.merchantName')">
						<el-select v-model="form.business_no" :placeholder="$t('main.orderMenu.pleaseSelect')" style="display: block;" value-ley="id" @change="chooseTra">
							<el-option v-for="item in traList" :key="item.business_no" :label="item.business_name" :value="item.business_no"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('main.orderMenu.orderAmount')">
						<el-input v-model="form.money" :placeholder="$t('main.orderMenu.pleaseEnterTheAmount')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('main.orderMenu.collectionMethod')">
						<el-select v-model="form.payType" :placeholder="$t('main.orderMenu.pleaseSelect')" style="display: block;" @change="choosePayType">
							<el-option v-for="item in payList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<!-- <el-form-item :label="$t('main.orderMenu.currency')">
						<el-select v-model="form.cur_id" :placeholder="$t('main.orderMenu.pleaseSelect')" style="display: block;" @change="choosePayType">
							<el-option v-for="item in traderCurrencyList" :key="item.cur_id" :label="item.cur_name" :value="item.cur_id"></el-option>
						</el-select>
					</el-form-item> -->
					<el-form-item :label="$t('main.orderMenu.passageway')">
						<el-select v-model="form.payway_type" :placeholder="$t('main.orderMenu.pleaseSelect')" style="display: block;">
							<el-option v-for="item in passagewayDataList" :key="item.payway_type_id" :label="item.name" :value="item.payway_type_id"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="提交时间(不选即为当前时间)">
						<el-date-picker
						      v-model="form.add_time"
						      type="datetime"
						      placeholder="选择提交日期时间">
						    </el-date-picker>
					</el-form-item>
					
					<el-form-item label="付款人姓名(可不填)">
						<el-input v-model="form.pay_name" placeholder="请填写付款人姓名"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="onSubmit">确定</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				traList: [],
				payList: [],
				passagewayDataList: [],
				traderCurrencyList:[],
				business_no: '',
				loading:false,
				form: {
					business_no: '',
					money: '',
					payType: '',
					payway_type: '',
					cur_id:'',
					add_time: '',
					pay_name: '',
				}
			}
		},
		created() {
			this.traderList()
			this.paywayAll()
		},
		methods: {
			onSubmit() {
				if(isEmpty(this.form.business_no,'请选择商家')){
					return
				}
				if(isEmpty(this.form.money,'请输入金额')){
					return
				}
				if(!isMoney(this.form.money,'金额只能是数字，且最多保留两位小数')){
					return
				}
				if(isEmpty(this.form.payType,'请选择支付方式')){
					return
				}
				if(isEmpty(this.form.payway_type,'请选择通道')){
					return
				}
				if(this.form.add_time != ''){
					this.form.add_time = this.$util.timestampToTime(Date.parse(new Date(this.form.add_time)))
				}else{
					delete this.form.add_time
				}
				let data = this.form
				// console.log(data)
				this.loading=true
				this.$api.addOrder(data).then(res=>{
					this.loading=false
					if(res.status == 1){
						this.$message({type:'success',message:'补增订单成功'})
						 this.form = {
							 business_no: '',
							 money: '',
							 payType: '',
							 passage: '',
							 add_time: '',
							 pay_name: '',
						}
						this.business_no=''
						this.$alert('补增订单编号为:' + res.data, '提示', {
							confirmButtonText: '确定',
						});
					}else{
						this.$message({type:'error',message:res.msg})
					}
				}).catch(error=>{
					this.loading=false
				})
			},
			traderList() {
				let data = {
					size: 10000,
					page: 1
				}
				this.$api.traderList(data).then(res => {
					if (res.status == 1) {
						this.traList = res.data.data
					}
				})
			},
			paywayAll() {
				let data = {
					size: 10000,
					page: 1,
					hasRepay: 2, //可用通道
				}
				this.$api.paywayAll(data).then(res => {
					if (res.status == 1) {
						this.payList = res.data.data
					}
				})
			},
			chooseTra(val) {
				this.business_no = val	
				// 获取币种
				// this.getTraderCurrencyList(val)
			},
			getTraderCurrencyList(val){
				// 清空条件
				this.traderCurrencyList = []
				this.form.cur_id = ''
				this.$api.traderCurrencysList({business_no:val}).then(res => {
					if (res.status == 1) {
						this.traderCurrencyList =  res.data
					}
				})
			},
			choosePayType(val) {
				if(isEmpty(this.business_no,"商家不能为空")){
					return
				}
				this.passagewayList()
			},
			passagewayList() {
				this.passagewayDataList = []
				this.form.payway_type = ''
				if(this.form.payType){
					let data = {					
						business_no:this.business_no,
						pay_type:this.form.payType,
					}
					this.$api.passagewayList(data).then(res => {
						if (res.status == 1) {
							this.passagewayDataList =  res.data
						}
					})
				}
				
			},
			
		},
		watch: {
			'business_no': function(val, oldval) {
				let hasBusi = false;
				for (let i = 0; i < this.traList.length; i++) {
					if (val == this.traList[i].business_no) {
						this.form.business_no = val;
						hasBusi = true;
						if(this.form.payType && this.form.payType !=""){
							this.passagewayList()
						}
						// this.getTraderCurrencyList(val)
						break;
					}
				}
				if(!hasBusi){
					this.form.business_no=''
				}
			}, //键路径必须加上引号
		},
	}
</script>

<style lang="scss" scoped="scoped">
	@import '@/style/variables.scss';

	.content {
		.pageTitle {
			background-color: $navSubColor;
			padding: 10px;
		}

		.tips {
			width: 460px;
		}

		.form {
			width: 480px;
		}
	}
</style>
